import { Link } from 'gatsby';
import React from 'react';
import * as styles from '../../styles/Button.module.scss';

const Button = (props) => {
    return (
       <button className={styles.ButtonWrap}><Link className={styles.ButtonCopy} to={props.url.text}>{props.copy.text}</Link></button>
    );
}

export default Button;