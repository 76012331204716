import React from 'react';
import { Hero } from '../Hero/Hero';
import Service from '../Service/Service';

const Homepage = ({ heroData, homepageServices }) => {
    let homeServices = null;
    if (homepageServices) {
        homeServices = homepageServices.map((service, index) => {
            return (
                <Service data={service} key={index} /> 
            );
        });
    }

    return (
        <div>
            <Hero heading={heroData.heading} subHeading={heroData.sub_heading} ctaCopy={heroData.cta_copy} ctaUrl={heroData.cta_url}/>
            {homeServices}
        </div>
    );
};

export default Homepage;