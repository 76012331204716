import * as React from 'react';
import { graphql } from 'gatsby';
import * as styles from '../../styles/BasicStatistic.module.scss';

const BasicStatistic = ({ slice }) => {
    let basicStat = slice.items.map((stat, index) => {
        return (
            <div key={index} className={styles.BasicStatisticItemWrap}>
                <span>{stat.statistic_number}%</span>
                <p>{stat.statistic_copy.text}</p>
            </div>
        );
    })
    return (
        <div className={styles.BasicStatisticItemsWrap}>
            {basicStat}
        </div>
    );
};

export const query = graphql`
    fragment ServiceDataBodyBasicStatistic on PrismicServiceDataBodyBasicStatistic {
        items {
            statistic_copy {
              text
            }
            statistic_number
          }
    }
`;

export default BasicStatistic; 