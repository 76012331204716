import React from 'react';
import * as styles from '../../styles/Service.module.scss';
import { PrismicRichText, SliceZone } from '@prismicio/react';
import Button from '../Button/Button';
import { components } from '../Slices';
import ServiceVideo from '../../assets/Dollhouse.mp4';

const Service = (data) => {
    return (
        <section className={styles.ServiceSectionWrap}>
            <div id="vr-tours" className={styles.ServiceScrollAnchor}></div>
            <h2>{data.data.homepage_service.document.data.service_title.text}</h2>
            <PrismicRichText field={data.data.homepage_service.document.data.service_copy.richText} />
            <video autoPlay={true} controls="" loop={true} muted={true} playsInline={false} width="100%" poster="" height={'100%'}>
                <source src={ServiceVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Button url={data.data.homepage_service.document.data.service_cta} copy={data.data.homepage_service.document.data.cta_copy} />
            <SliceZone slices={data.data.homepage_service.document.data.body} components={components} />
        </section>
    )
}

export default Service;