import * as React from 'react';
import { graphql } from 'gatsby';
import * as styles from '../../styles/DecorativeStatistic.module.scss';
import { useSelector } from 'react-redux';

const DecorativeStatistic = ({ slice }) => {
  let decorativeStats = null;
  const isMobile = useSelector(state => state.isMobile);
  if (slice.items) {
    decorativeStats = slice.items.map((stat, index) => {
      return (
        <div key={index} className={styles.DecorativeStatItemWrap}>
          <div className={styles.DecorativeStatNumberWrap}>
            <span className={styles.DecorativeStatNumber}>{stat.decorative_statistic_number}%</span>
            <p>{stat.decorative_statistic_heading.text}</p>
          </div>
          <img src={stat.decorative_statistic_image.url} alt="" />
        </div>
      )
    });
  }
  return (
    <div className={styles.DecorativeStatItemsWrap}>
      {decorativeStats}
    </div>
  );
}

export const query = graphql`
    fragment ServiceDataBodyDecorativeStatistic on PrismicServiceDataBodyDecorativeStatistic {
        items {
            decorative_statistic_heading {
              text
            }
            decorative_statistic_image {
              url
            }
            decorative_statistic_number
          }
    }
`;

export default DecorativeStatistic;