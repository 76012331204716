import React from 'react';
import { graphql } from 'gatsby';

import * as styles from '../../styles/CopyBlock.module.scss';
import { PrismicRichText } from '@prismicio/react';

const CopyBlock = ({ slice }) => {
  return (
    <div className={styles.CopyBlockWrap}>
      <h2>{slice.primary.copy_block_heading.text}</h2>
      <PrismicRichText field={slice.primary.copy_block_body.richText} />
    </div>
  );
}

export const query = graphql`
    fragment ServiceDataBodyCopyBlock on PrismicServiceDataBodyCopyBlock {
        primary {
            copy_block_heading {
              text
            }
            copy_block_body {
              richText
            }
          }
    }
`;

export default CopyBlock;