import * as React from 'react';
import { graphql } from 'gatsby';
import Button from '../Button/Button';

const CallToAction = ({ slice }) => {
   return <Button url={slice.primary.cta_url} copy={slice.primary.cta_copy} />;
}

export const query = graphql`
    fragment ServiceDataBodyCallToAction on PrismicServiceDataBodyCallToAction {
        primary {
            cta_url {
              text
            }
            cta_copy {
              text
            }
          }
    }
`;

export default CallToAction;