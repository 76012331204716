import * as React from 'react';
import { graphql } from 'gatsby';
import * as styles from '../../styles/CaseStudyCard.module.scss';
import * as swiperStyles from '../../styles/Swiper.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from "swiper";


const CaseStudyCard = ({ slice }) => {
  const pagination = {
    clickable: true,
    horizontalClass: swiperStyles.Pagination,
    bulletClass: swiperStyles.PaginationBullet,
    bulletActiveClass: swiperStyles.PaginationActiveBullet,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  let cards = null;
  if (slice.items) {
    cards = slice.items.map((card, index) => {
      return (
        <SwiperSlide className={swiperStyles.SwiperSlide} key={index}>
          <div dangerouslySetInnerHTML={{ __html: card.matterport_link.text }} />
        </SwiperSlide>
       
      );
    })
  }
  return (
    <Swiper
      spaceBetween={75}
      slidesPerView={1}
      className={swiperStyles.SwiperWrap}
      pagination={pagination}
      modules={[Pagination]}
    >
      {cards}
    </Swiper>
  );
}

export const query = graphql`
    fragment ServiceDataBodyCaseStudyCard on PrismicServiceDataBodyCaseStudyCard {
        items {
            case_category_url {
              text
            }
            case_category_heading {
              text
            }
            matterport_link {
              text
            }
          }
    }
`;

export default CaseStudyCard;