import CopyBlock from './CopyBlock';
import CallToAction from './CallToAction';
import CaseStudyCard from './CaseStudyCard';
import BasicStatistic from './BasicStatistic';
import DecorativeStatistic from './DecorativeStatistic';

export const components = {
    copy_block: CopyBlock,
    call_to_action: CallToAction,
    case_study_card: CaseStudyCard,
    basic_statistic: BasicStatistic,
    decorative_statistic: DecorativeStatistic
}