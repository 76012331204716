import * as React from 'react'
import * as styles from '../../styles/Hero.module.scss';
import Button from '../Button/Button';
import HomeVideo from '../../assets/hp-video.mp4';
import HomeVideoDesktop from '../../assets/hp-video-desktop.mp4';
import { useSelector } from 'react-redux';

export const Hero = ({heading, subHeading, ctaCopy, ctaUrl}) => {
    const isMobile = useSelector(state => state.isMobile);
    
    return (
        <section className={styles.HeroSection}>
            <video autoPlay={true} controls="" loop={true} muted playsInline={true} width="100%" poster="" height={'100%'}>
                <source src={isMobile ? HomeVideo : "https://static.matterport.com/mp_www/MP_ListingDay_Pro-Res_NoEndCard_4-3.mp4"} type="video/mp4" />
                    Your browser does not support the video tag.
            </video>
            <div className={styles.HeroContentWrap}>
                <h1>{heading.text}</h1>
                <h2>{subHeading.text}</h2>
                <Button url={ctaUrl} copy={ctaCopy} />
            </div>
        </section>
    );
}